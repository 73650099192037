import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 查询定时任务列表
 * @param params
 */
export function getJobList(params) {
  return request({
    url: envInfo.bgApp.clockyPath + '/job/list',
    method: 'get',
    params
  })
}

/**
 * 查询定时任务执行记录列表
 * @param params
 */
export function getJobTraceList(params) {
  return request({
    url: envInfo.bgApp.clockyPath + '/job/trace/list',
    method: 'get',
    params
  })
}

/**
 * 新建定时任务
 * @param params
 */
export function createJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + '/job/create',
    method: 'post',
    data: params
  })
}

/**
 * 更新定时任务
 * @param params
 */
export function updateJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + '/job/update',
    method: 'put',
    data: params
  })
}

/**
 * 删除定时任务
 * @param params
 */
export function deleteJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/delete/${params.jobId}`,
    method: 'delete',
  })
}

/**
 * 批量删除定时任务
 * @param params
 */
export function batchDeleteJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/deleteJobs`,
    method: 'post',
    data: params
  })
}

/**
 * 暂停定时任务
 * @param params
 */
export function pauseJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/pause/${params.jobId}`,
    method: 'post',
    data: params
  })
}

/**
 * 批量暂停定时任务
 * @param params
 */
export function batchPauseJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/pauseJobs`,
    method: 'post',
    data: params
  })
}

/**
 * 恢复定时任务
 * @param params
 */
export function resumeJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/resume/${params.jobId}`,
    method: 'post',
    data: params
  })
}

/**
 * 批量恢复定时任务
 * @param params
 */
export function batchResumeJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/resumeJobs`,
    method: 'post',
    data: params
  })
}

/**
 * 立即执行一次定时任务
 * @param params
 */
export function triggerJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/trigger/${params.jobId}`,
    method: 'post',
    data: params
  })
}

/**
 * 批量执行定时任务
 * @param params
 */
export function batchTriggerJob(params) {
  return request({
    url: envInfo.bgApp.clockyPath + `/job/triggerJobs`,
    method: 'post',
    data: params
  })
}

/**
 * 获取cron提示列表
 */
export function getCronList() {
  return request({
    url: envInfo.bgApp.lookupPath + '/lookup/item/find/list?classifyCode=jobCronHint',
    method: 'get',
  })
}
