var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "datetimerange",
              "range-separator": _vm.$t("commons.rangeSeparator"),
              "start-placeholder": _vm.$t("commons.startDate"),
              "end-placeholder": _vm.$t("commons.endDate"),
            },
            on: { change: _vm.setDateRange },
            model: {
              value: _vm.job.pickerOpts.pickerData,
              callback: function ($$v) {
                _vm.$set(_vm.job.pickerOpts, "pickerData", $$v)
              },
              expression: "job.pickerOpts.pickerData",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: _vm.$t("clocky.groupName"),
              maxlength: "50",
              clearable: "",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleJobFilter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.job.listQuery.groupName,
              callback: function ($$v) {
                _vm.$set(_vm.job.listQuery, "groupName", $$v)
              },
              expression: "job.listQuery.groupName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: _vm.$t("clocky.jobName"),
              maxlength: "50",
              clearable: "",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleJobFilter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.job.listQuery.jobName,
              callback: function ($$v) {
                _vm.$set(_vm.job.listQuery, "jobName", $$v)
              },
              expression: "job.listQuery.jobName",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { clearable: "", placeholder: _vm.$t("commons.status") },
              model: {
                value: _vm.job.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.job.listQuery, "status", $$v)
                },
                expression: "job.listQuery.status",
              },
            },
            _vm._l(_vm.job.statusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: { clearable: "", placeholder: _vm.$t("clocky.jobType") },
              model: {
                value: _vm.job.listQuery.jobType,
                callback: function ($$v) {
                  _vm.$set(_vm.job.listQuery, "jobType", $$v)
                },
                expression: "job.listQuery.jobType",
              },
            },
            _vm._l(_vm.job.jobTypeOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("clocky.statefulFlag"),
              },
              model: {
                value: _vm.job.listQuery.statefulFlag,
                callback: function ($$v) {
                  _vm.$set(_vm.job.listQuery, "statefulFlag", $$v)
                },
                expression: "job.listQuery.statefulFlag",
              },
            },
            _vm._l(_vm.job.statefulFlagOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                type: "primary",
                icon: "el-icon-search",
                loading: _vm.job.listLoading,
              },
              on: { click: _vm.handleJobFilter },
            },
            [_vm._v(_vm._s(_vm.$t("commons.search")) + "\n    ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-plus",
                size: "mini",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.addJob },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("clocky.addJob")) + "\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.job.multipleSelection.length === 0,
                icon: "el-icon-video-pause",
                type: "primary",
                size: "mini",
                plain: "",
              },
              on: { click: _vm.handleJobBatchPause },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("commons.batchPause")) + "\n    "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.job.multipleSelection.length === 0,
                icon: "el-icon-refresh",
                type: "primary",
                size: "mini",
                plain: "",
              },
              on: { click: _vm.handleJobBatchResume },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("commons.batchResume")) + "\n    "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.job.multipleSelection.length === 0,
                icon: "el-icon-video-play",
                type: "primary",
                size: "mini",
                plain: "",
              },
              on: { click: _vm.handleJobBatchTrigger },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("clocky.batchTrigger")) + "\n    "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.job.multipleSelection.length === 0,
                icon: "el-icon-delete",
                type: "danger",
                size: "mini",
                plain: "",
              },
              on: { click: _vm.handleJobBatchDelete },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("commons.batchDelete")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.job.listLoading,
              expression: "job.listLoading",
            },
          ],
          attrs: {
            data: _vm.job.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            stripe: "",
          },
          on: {
            "sort-change": _vm.handleSort,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              type: "selection",
              align: "center",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "groupName",
              label: _vm.$t("clocky.groupName"),
              width: "150",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobName",
              label: _vm.$t("clocky.jobName"),
              width: "220",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("clocky.jobShard"),
              width: "130",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("jobShardFilter")(scope.row.shardFlag)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("clocky.jobType"),
              width: "130",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("jobTypeFilter")(scope.row.jobType)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("clocky.statefulFlag"),
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("statefulFlagFilter")(scope.row.statefulFlag)
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "creationDate",
              sortable: "custom",
              align: "center",
              label: _vm.$t("commons.creationDate"),
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              scope.row.creationDate,
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("commons.status"),
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("statusTagFilter")(scope.row.status),
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(scope.row.status)) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cron",
              label: _vm.$t("clocky.cron"),
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.edit
                      ? [
                          _c("job-cron", {
                            attrs: { value: scope.row.cron },
                            on: {
                              onInputCron: function ($event) {
                                return _vm.onInputCron($event, scope.row)
                              },
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.cron))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobContent",
              label: _vm.$t("clocky.jobContent"),
              width: "600",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.edit
                      ? [
                          _c("el-input", {
                            attrs: {
                              maxlength: "333",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: scope.row.jobContent,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "jobContent", $$v)
                              },
                              expression: "scope.row.jobContent",
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.jobContent))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: _vm.$t("commons.actions"),
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          "popper-class": "job-operation-popover",
                          trigger: "hover",
                        },
                      },
                      [
                        scope.row.edit
                          ? [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("commons.save"),
                                    placement: "bottom",
                                    "open-delay": _vm.openDelay,
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      circle: "",
                                      icon: "el-icon-check",
                                      loading: scope.row.processing,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleJobUpdate(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("commons.cancel"),
                                    placement: "bottom",
                                    "open-delay": _vm.openDelay,
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      size: "mini",
                                      icon: "el-icon-close",
                                      circle: "",
                                      type: "warning",
                                      disabled: scope.row.processing,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelJobEdit(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              scope.row.status === 2
                                ? [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("clocky.triggerOnce"),
                                          placement: "bottom",
                                          "open-delay": _vm.openDelay,
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            icon: "el-icon-video-play",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.triggerJob(scope.row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("commons.pause"),
                                          placement: "bottom",
                                          "open-delay": _vm.openDelay,
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            icon: "el-icon-video-pause",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.pauseJob(scope.row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              scope.row.status === 0
                                ? [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("commons.resume"),
                                          placement: "bottom",
                                          "open-delay": _vm.openDelay,
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            icon: "el-icon-refresh",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resumeJob(scope.row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("commons.edit"),
                                          placement: "bottom",
                                          "open-delay": _vm.openDelay,
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            icon: "el-icon-edit",
                                            type: "success",
                                            circle: "",
                                            disabled: scope.row.processing,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editJobHander(
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("commons.delete"),
                                          placement: "bottom",
                                          "open-delay": _vm.openDelay,
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                            icon: "el-icon-delete",
                                            circle: "",
                                            loading: scope.row.processing,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleJobDelete(
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("clocky.viewTrace"),
                                    placement: "bottom",
                                    "open-delay": _vm.openDelay,
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-tickets",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showJobTrace(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                        _c("el-button", {
                          attrs: {
                            slot: "reference",
                            icon: "el-icon-more-outline",
                            size: "mini",
                            round: "",
                          },
                          slot: "reference",
                        }),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.job.total > 0,
            expression: "job.total > 0",
          },
        ],
        attrs: {
          total: _vm.job.total,
          page: _vm.job.listQuery.current,
          limit: _vm.job.listQuery.rowCount,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.job.listQuery, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.job.listQuery, "rowCount", $event)
          },
          pagination: _vm.getJobList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.job.dialogVisible,
            width: "30%",
            "close-on-click-modal": false,
            "before-close": _vm.closeAddJobDialog,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.job, "dialogVisible", $event)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(
                _vm._s(
                  _vm.job.editRow.jobId
                    ? _vm.$t("clocky.editJob")
                    : _vm.$t("clocky.addJob")
                ) + "\n    "
              ),
            ],
            1
          ),
          _vm.job.dialogVisible
            ? _c("add-job", {
                ref: "addJobForm",
                attrs: { dataset: _vm.job.editRow },
              })
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeAddJobDialog } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.job.createLoading,
                      expression: "job.createLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleJobCreate },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("commons.save")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "5vh",
            width: "1000px",
            visible: _vm.jobTrace.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.jobTrace, "dialogVisible", $event)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(
                _vm._s(_vm.$t("clocky.viewTraceTitle", _vm.jobTrace.job)) +
                  "\n    "
              ),
            ],
            1
          ),
          _c("job-trace", { attrs: { currentJob: _vm.jobTrace.job } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }