<template>
  <el-form
    label-position="top"
    :model="job.form"
    ref="jobForm"
    :rules="job.formRules"
  >
    <el-row>
      <el-col :span="11">
        <el-form-item :label="$t('clocky.jobName')" prop="jobName">
          <el-input
            v-model="job.form.jobName"
            :disabled="dataset.jobId ? true : false"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="2">
        <el-form-item :label="$t('clocky.groupName')" prop="groupName">
          <el-input
            v-model="job.form.groupName"
            :disabled="dataset.jobId ? true : false"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <el-form-item :label="$t('clocky.jobShard')" prop="shardFlag">
          <el-radio-group v-model="job.form.shardFlag">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('clocky.jobShardYReason')"
              placement="top-start"
            >
              <el-radio-button label="Y">{{
                $t("clocky.shardFlagY")
              }}</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('clocky.jobShardNReason')"
              placement="top-start"
            >
              <el-radio-button label="N">{{
                $t("clocky.shardFlagN")
              }}</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="2">
        <el-form-item :label="$t('clocky.statefulFlag')" prop="statefulFlag">
          <el-radio-group v-model="job.form.statefulFlag">
            <el-radio-button label="Y">{{
              $t("clocky.statefulFlagY")
            }}</el-radio-button>
            <el-radio-button label="N">{{
              $t("clocky.statefulFlagN")
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <el-form-item :label="$t('clocky.jobType')" prop="jobType">
          <el-radio-group v-model="job.form.jobType">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('clocky.jobTypeRReason')"
              placement="top-start"
            >
              <el-radio-button label="REST">{{
                $t("clocky.jobTypeREST")
              }}</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('clocky.jobTypeCReason')"
              placement="top-start"
            >
              <el-radio-button label="CLOUD">{{
                $t("clocky.jobTypeCLOUD")
              }}</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="2">
        <el-form-item :label="$t('clocky.cron')" prop="cron">
          <job-cron :value="job.form.cron" @onInputCron="onInputCron" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="job.form.shardFlag === 'N'">
      <el-form-item
        label="指定租户"
        prop="tenantIds"
        :rules="[
          {
            required: job.form.jobType === 'CLOUD',
            trigger: ['blur', 'change'],
            message: $t('validate.required'),
          },
        ]"
      >
        <tenant-select
          :model="2"
          :multiple="true"
          v-model="job.form.tenantIds"
          :style="{ width: '100%' }"
        ></tenant-select>
      </el-form-item>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item :label="$t('clocky.jobContent')" prop="jobContent">
          <el-input
            type="textarea"
            maxlength="160"
            :show-word-limit="true"
            v-model="job.form.jobContent"
            :autosize="{ minRows: 4, maxRows: 6 }"
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { createJob, updateJob } from "@/api/ruge/clocky/job";
import JobCron from "./JobCron";
import TenantSelect from "@/components/TenantSelect";
import { cloneDeep } from "lodash";
export default {
  name: "AddJob",
  components: { JobCron, TenantSelect },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        console.log("datas", datas);
        if (!datas.jobId) return;
        const {
          jobName,
          jobType,
          statefulFlag,
          shardFlag,
          groupName,
          cron,
          jobContent,
          tenantIds,
        } = cloneDeep(datas);
        this.job.form.jobName = jobName;
        this.job.form.jobType = jobType;
        this.job.form.statefulFlag = statefulFlag;
        this.job.form.shardFlag = shardFlag;
        this.job.form.groupName = groupName;
        this.job.form.cron = cron;
        this.job.form.jobContent = jobContent;
        this.job.form.tenantIds = tenantIds;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      // 定时任务相关参数
      job: {
        // 表单
        form: {
          jobName: "",
          jobType: "REST",
          statefulFlag: "Y",
          shardFlag: "Y",
          groupName: "",
          cron: "",
          jobContent: "",
          tenantIds: [],
        },
        // 表单校验规则
        formRules: {
          jobName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          jobType: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          statefulFlag: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          groupName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          cron: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          jobContent: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  methods: {
    // 提交表单
    submit(resolve, reject) {
      this.$refs.jobForm.validate((valid) => {
        if (valid) {
          // 调用服务
          createJob(this.job.form)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              // 成功执行回调
              resolve();
            })
            .catch((error) => {
              console.log(`添加失败，原因 => ${error}`);
              reject();
            });
        } else {
          console.log("error handleJobCreate!!");
          reject();
        }
      });
    },
    update(resolve, reject) {
      this.$refs.jobForm.validate((valid) => {
        if (valid) {
          // 调用服务
          this.job.form.jobId = this.dataset.jobId;
          updateJob(this.job.form)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              // 成功执行回调
              resolve();
            })
            .catch((error) => {
              console.log(`添加失败，原因 => ${error}`);
              reject();
            });
        } else {
          console.log("error handleJobCreate!!");
          reject();
        }
      });
    },
    // 重置表单
    reset() {
      this.$refs.jobForm.resetFields();
    },
    // 为cron赋值
    onInputCron(val) {
      this.job.form.cron = val;
    },
  },
};
</script>
