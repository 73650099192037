var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-autocomplete", {
    staticStyle: { width: "100%" },
    attrs: {
      "popper-class": "cron-autocomplete",
      value: _vm.value,
      clearable: "",
      placeholder: _vm.$t("commons.pleaseInput"),
      "fetch-suggestions": _vm.searchCron,
    },
    on: { input: _vm.updateCron },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "cron" }, [_vm._v(_vm._s(item.value))]),
            _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }