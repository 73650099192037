<template>
    <el-autocomplete
            popper-class="cron-autocomplete"
            :value="value"
            clearable
            :placeholder="$t('commons.pleaseInput')"
            style="width: 100%;"
            @input="updateCron"
            :fetch-suggestions="searchCron">
        <template slot-scope="{ item }">
            <div class="cron">{{ item.value }}</div>
            <span class="name">{{ item.name }}</span>
        </template>
    </el-autocomplete>
</template>

<script>
    import {getCronList} from '@/api/ruge/clocky/job'

    export default {
        name: 'JobCron',
        props: {
            value: String,
            row:Object,
        },
        data() {
            return {
                // cron提示列表
                cronList: [],
            }
        },
        created() {
            this.getCronList();
        },
        methods: {
            // 获取cron提示列表
            getCronList() {
                getCronList().then(response => {
                    let cronList = [];
                    response.forEach(function (item) {
                        cronList.push({'value': item.itemCode, 'name': item.itemName})
                    });
                    this.cronList = cronList;
                }).catch(error => {
                    console.log(`查询cron提示列表失败，原因 => ${error}`)
                })
            },
            // 获取cron提示
            searchCron(queryString, cb) {
                // 过滤规则，全模糊
                let cronFilter = function (queryString) {
                    return (state) => {
                        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
                    };
                };
                let cronList = this.cronList;
                let results = queryString ? cronList.filter(cronFilter(queryString)) : cronList;
                cb(results);
            },
            // 更新父组件的值
            updateCron(val) {
                this.$emit('onInputCron', val);
            }
        }
    }
</script>
<style lang="scss">
    .cron-autocomplete {
        li {
            line-height: normal;
            padding: 7px;

            .cron {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .name {
                font-size: 12px;
                color: #b4b4b4;
            }

            .highlighted .addr {
                color: #ddd;
            }
        }
    }
</style>
