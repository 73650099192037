<template>
    <div>
        <el-form :inline="true" :model="jobTrace.listQuery" ref="searchJobTraceForm">
            <el-form-item prop="finalStatus">
                <el-select v-model="jobTrace.listQuery.finalStatus" clearable :placeholder="$t('commons.status')"
                           style="width: 200px;" class="filter-item">
                    <el-option
                            v-for="item in jobTrace.statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        class="filter-item"
                        v-model="jobTrace.pickerOpts.pickerData"
                        type="datetimerange"
                        :picker-options="jobTrace.pickerOpts"
                        @change="setDateRange"
                        :range-separator="$t('commons.rangeSeparator')"
                        :start-placeholder="$t('commons.startDate')"
                        :end-placeholder="$t('commons.endDate')">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button class="filter-item" type="primary" icon="el-icon-search" :loading="jobTrace.listLoading" @click="handleJobTraceFilter">{{
                    $t('commons.search') }}
                </el-button>
            </el-form-item>
        </el-form>
        <el-table
                v-loading="jobTrace.listLoading"
                :data="jobTrace.list"
                @sort-change="handleJobTraceSort"
                element-loading-text="Loading"
                size="small"
                border
                fit
                highlight-current-row
                stripe>
            <el-table-column type="index" align="center" :resizable="false" :label="$t('commons.index')" width="80">
            </el-table-column>
            <el-table-column align="center" prop="creationDate" sortable="custom" :label="$t('commons.creationDate')" width="200">
                <template slot-scope="scope">
                    <i class="el-icon-time"/>
                    <span> {{ scope.row.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="totalTime" sortable="custom" :label="$t('clocky.totalTime')" width="120" align="center">
            </el-table-column>
            <el-table-column :label="$t('commons.status')" prop="finalStatus" sortable="custom" width="100" align="center">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.finalStatus | finalStatusTagFilter">{{ scope.row.finalStatus | finalStatusFilter }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="returnValue" :label="$t('clocky.returnValue')">
            </el-table-column>
        </el-table>
        <pagination v-show="jobTrace.total>0" :total="jobTrace.total" :page.sync="jobTrace.listQuery.current"
                    :limit.sync="jobTrace.listQuery.rowCount"
                    @pagination="getJobTraceList"/>
    </div>
</template>

<script>
    import {getJobTraceList} from '@/api/ruge/clocky/job'
    import Pagination from '@/components/Pagination'
    import i18n from '@/lang'
    import { buildSortStr } from '@/utils/global'


    export default {
        name: 'JobTrace',
        components: {Pagination},
        props: {
            // 当前定时任务
            currentJob: {
                required: true,
                type: Object,
            },
        },
        filters: {
            // 返回任务执行状态tag效果
            finalStatusTagFilter(status) {
                return status === 1 ? 'success' : 'danger';
            },
            // 返回国际化后的任务执行状态
            finalStatusFilter(status) {
                return i18n.t('clocky.jobTraceStatus' + status);
            },
        },
        data() {
            let opt = {
                // Tooltip显示延迟时间(毫秒)
                openDelay: 1000,
                // 定时任务执行记录相关参数
                jobTrace: {
                    // 对话框是否可见，默认不可见
                    dialogVisible: false,
                    // 列表参数
                    list: null,
                    total: 0,
                    listLoading: true,
                    listQuery: {
                        current: 1,
                        rowCount: 10,
                        // 排序条件,格式：column1:asc;column2:desc
                        sortStr: null,
                        jobId: null,
                        finalStatus: null,
                        startDate: null,
                        endDate: null,
                    },
                    // 状态下拉选项
                    statusOptions: [],
                    // 日期范围查询组件
                    pickerOpts: {
                        // 默认日期
                        pickerData: null,
                        // 快捷选项
                        shortcuts: null,
                    }
                }
            };
            // 设置定时任务执行状态下拉选项
            this.setJobTraceStatusOptions(opt);
            // 设置Picker快捷选项
            this.setPickerShortcuts(opt);
            return opt;
        },
        created() {
            this.showJobTrace();
        },
        watch: {
            // 切换语言重新设置状态下拉选项
            language: function () {
                this.setJobTraceStatusOptions();
                this.setPickerShortcuts();
            },
            currentJobId: function () {
                this.showJobTrace();
            }
        },
        computed: {
            language() {
                return this.$store.getters.language
            },
            currentJobId() {
                return this.currentJob.jobId;
            }
        },
        methods: {
            // 查看执行记录内容
            showJobTrace() {
                this.clearJobTrace();
                this.jobTrace.list = null;
                this.jobTrace.total = 0;
                this.jobTrace.listQuery.current = 1;
                this.jobTrace.listQuery.rowCount = 10;
                this.jobTrace.listQuery.jobId = this.currentJobId;
                this.getJobTraceList();
            },
            // 消除上次的执行记录
            clearJobTrace() {
                // 重置搜索表单
                let formObj = this.$refs.searchJobTraceForm;
                if (formObj) {
                    formObj.resetFields();
                }
                // 日期范围控件比较特殊，无法绑定到form的属性中，所以需要手动清空日期
                this.jobTrace.listQuery.startDate = null;
                this.jobTrace.listQuery.endDate = null;
                this.jobTrace.pickerOpts.pickerData = null;
            },
            // 获取执行记录列表
            getJobTraceList() {
                this.jobTrace.listLoading = true;
                getJobTraceList(this.jobTrace.listQuery).then(response => {
                    this.jobTrace.list = response.rows;
                    this.jobTrace.total = response.total;
                    this.jobTrace.listLoading = false;
                }).catch(error => {
                    this.jobTrace.listLoading = false;
                    console.log(`查询失败，原因 => ${error}`)
                })
            },
            // 处理执行记录动态排序
            handleJobTraceSort(column) {
                this.jobTrace.listQuery.sortStr = buildSortStr(column);
                this.getJobTraceList()
            },
            // 查询执行记录
            handleJobTraceFilter() {
                this.jobTrace.listQuery.current = 1
                this.getJobTraceList()
            },
            // 设置状态下拉选项
            setJobTraceStatusOptions(opt = this) {
                opt.jobTrace.statusOptions = [{
                    value: 0,
                    label: this.$t('clocky.jobTraceStatus0')
                }, {
                    value: 1,
                    label: this.$t('clocky.jobTraceStatus1')
                }]
            },
            // 设置日期范围查询条件
            setDateRange(dateRange) {
                if (dateRange) {
                    this.jobTrace.listQuery.startDate = dateRange[0].getTime();
                    this.jobTrace.listQuery.endDate = dateRange[1].getTime();
                } else {// 清空
                    this.jobTrace.listQuery.startDate = null;
                    this.jobTrace.listQuery.endDate = null;
                }
            },
            // 设置Picker快捷选项
            setPickerShortcuts(opt = this) {
                opt.jobTrace.pickerOpts.shortcuts =  [{
                    text: this.$t('commons.lastDay'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('commons.lastWeek'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('commons.lastMonth'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    }
</script>
