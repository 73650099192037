<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        class="filter-item"
        v-model="job.pickerOpts.pickerData"
        type="datetimerange"
        @change="setDateRange"
        :range-separator="$t('commons.rangeSeparator')"
        :start-placeholder="$t('commons.startDate')"
        :end-placeholder="$t('commons.endDate')"
      >
      </el-date-picker>
      <el-input
        :placeholder="$t('clocky.groupName')"
        v-model="job.listQuery.groupName"
        maxlength="50"
        clearable
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleJobFilter"
      />
      <el-input
        :placeholder="$t('clocky.jobName')"
        v-model="job.listQuery.jobName"
        maxlength="50"
        clearable
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleJobFilter"
      />
      <el-select
        v-model="job.listQuery.status"
        clearable
        :placeholder="$t('commons.status')"
        style="width: 120px"
        class="filter-item"
      >
        <el-option
          v-for="item in job.statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="job.listQuery.jobType"
        clearable
        :placeholder="$t('clocky.jobType')"
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="item in job.jobTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="job.listQuery.statefulFlag"
        clearable
        :placeholder="$t('clocky.statefulFlag')"
        style="width: 120px"
        class="filter-item"
      >
        <el-option
          v-for="item in job.statefulFlagOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        :loading="job.listLoading"
        @click="handleJobFilter"
        >{{ $t("commons.search") }}
      </el-button>
    </div>
    <div class="filter-container">
      <el-button
        icon="el-icon-plus"
        size="mini"
        type="primary"
        plain
        @click="addJob"
      >
        {{ $t("clocky.addJob") }}
      </el-button>
      <el-button
        :disabled="job.multipleSelection.length === 0"
        icon="el-icon-video-pause"
        type="primary"
        size="mini"
        plain
        @click="handleJobBatchPause"
      >
        {{ $t("commons.batchPause") }}
      </el-button>
      <el-button
        :disabled="job.multipleSelection.length === 0"
        icon="el-icon-refresh"
        type="primary"
        size="mini"
        plain
        @click="handleJobBatchResume"
      >
        {{ $t("commons.batchResume") }}
      </el-button>
      <el-button
        :disabled="job.multipleSelection.length === 0"
        icon="el-icon-video-play"
        type="primary"
        size="mini"
        plain
        @click="handleJobBatchTrigger"
      >
        {{ $t("clocky.batchTrigger") }}
      </el-button>
      <el-button
        :disabled="job.multipleSelection.length === 0"
        icon="el-icon-delete"
        type="danger"
        size="mini"
        plain
        @click="handleJobBatchDelete"
      >
        {{ $t("commons.batchDelete") }}
      </el-button>
    </div>
    <el-table
      v-loading="job.listLoading"
      :data="job.list"
      element-loading-text="Loading"
      @sort-change="handleSort"
      border
      fit
      highlight-current-row
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column fixed type="selection" align="center" width="50">
      </el-table-column>
      <el-table-column
        prop="groupName"
        :label="$t('clocky.groupName')"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="jobName"
        :label="$t('clocky.jobName')"
        width="220"
        align="center"
      >
      </el-table-column>
      <el-table-column
        :label="$t('clocky.jobShard')"
        width="130"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.shardFlag | jobShardFilter }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('clocky.jobType')" width="130" align="center">
        <template slot-scope="scope">
          {{ scope.row.jobType | jobTypeFilter }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('clocky.statefulFlag')"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.statefulFlag | statefulFlagFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="creationDate"
        sortable="custom"
        align="center"
        :label="$t('commons.creationDate')"
        width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>
            {{
              scope.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('commons.status')" width="100" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status | statusTagFilter"
            >{{ scope.row.status | statusFilter }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="cron"
        :label="$t('clocky.cron')"
        width="150"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <job-cron
              :value="scope.row.cron"
              @onInputCron="onInputCron($event, scope.row)"
            />
          </template>
          <span v-else>{{ scope.row.cron }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="jobContent"
        :label="$t('clocky.jobContent')"
        width="600"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input
              v-model="scope.row.jobContent"
              maxlength="333"
              :show-word-limit="true"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </template>
          <span v-else>{{ scope.row.jobContent }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        :label="$t('commons.actions')"
        width="80"
      >
        <template slot-scope="scope">
          <el-popover
            placement="left"
            popper-class="job-operation-popover"
            trigger="hover"
          >
            <template v-if="scope.row.edit">
              <el-tooltip
                :content="$t('commons.save')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <el-button
                  type="success"
                  size="mini"
                  circle
                  icon="el-icon-check"
                  :loading="scope.row.processing"
                  @click="handleJobUpdate(scope.row)"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                :content="$t('commons.cancel')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <el-button
                  size="mini"
                  icon="el-icon-close"
                  circle
                  type="warning"
                  :disabled="scope.row.processing"
                  @click="cancelJobEdit(scope.row)"
                >
                </el-button>
              </el-tooltip>
            </template>
            <template v-else>
              <template v-if="scope.row.status === 2">
                <el-tooltip
                  :content="$t('clocky.triggerOnce')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-video-play"
                    circle
                    @click="triggerJob(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.pause')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-video-pause"
                    circle
                    @click="pauseJob(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
              </template>
              <template v-if="scope.row.status === 0">
                <el-tooltip
                  :content="$t('commons.resume')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-refresh"
                    circle
                    @click="resumeJob(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.edit')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    type="success"
                    circle
                    :disabled="scope.row.processing"
                    @click="editJobHander(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.delete')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    :loading="scope.row.processing"
                    @click="handleJobDelete(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
              </template>
              <el-tooltip
                :content="$t('clocky.viewTrace')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-tickets"
                  circle
                  @click="showJobTrace(scope.row)"
                >
                </el-button>
              </el-tooltip>
            </template>
            <el-button
              icon="el-icon-more-outline"
              size="mini"
              round
              slot="reference"
            ></el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="job.total > 0"
      :total="job.total"
      :page.sync="job.listQuery.current"
      :limit.sync="job.listQuery.rowCount"
      @pagination="getJobList"
    />
    <!-- 添加定时任务对话框 -->
    <el-dialog
      :visible.sync="job.dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closeAddJobDialog"
    >
      <template slot="title">
        <title-icon />{{
          job.editRow.jobId ? $t("clocky.editJob") : $t("clocky.addJob")
        }}
      </template>
      <add-job
        ref="addJobForm"
        :dataset="job.editRow"
        v-if="job.dialogVisible"
      />
      <div slot="footer">
        <el-button @click="closeAddJobDialog">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleJobCreate"
          v-loading="job.createLoading"
        >
          {{ $t("commons.save") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 显示任务执行记录对话框 -->
    <el-dialog top="5vh" width="1000px" :visible.sync="jobTrace.dialogVisible">
      <template slot="title">
        <title-icon />{{ $t("clocky.viewTraceTitle", jobTrace.job) }}
      </template>
      <job-trace :currentJob="jobTrace.job" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getJobList,
  deleteJob,
  batchDeleteJob,
  updateJob,
  pauseJob,
  batchPauseJob,
  resumeJob,
  batchResumeJob,
  triggerJob,
  batchTriggerJob,
} from "@/api/ruge/clocky/job";
import Pagination from "@/components/Pagination";
import i18n from "@/lang";
import { JobTrace, AddJob, JobCron } from "./components";
import { buildSortStr } from "@/utils/global";

export default {
  name: "Clocky",
  components: { JobTrace, Pagination, AddJob, JobCron },
  filters: {
    // 返回状态tag效果
    statusTagFilter(status) {
      let str = "info";
      if (status == 2) {
        str = "success";
      } else if (status == 1 || status == 3) {
        str = "";
      }
      return str;
    },
    // 返回国际化后的状态
    statusFilter(status) {
      return i18n.t("clocky.jobStatus" + status);
    },
    // 返回国际化后的jobType
    jobTypeFilter(jobType) {
      return i18n.t("clocky.jobType" + jobType);
    },
    jobShardFilter(shardFlag) {
      return i18n.t("clocky.shardFlag" + shardFlag);
    },
    // 返回国际化后的statefulFlag
    statefulFlagFilter(statefulFlag) {
      return i18n.t("clocky.statefulFlag" + statefulFlag);
    },
  },
  data() {
    let opt = {
      // Tooltip显示延迟时间(毫秒)
      openDelay: 1000,
      // 定时任务相关参数
      job: {
        editRow: {},
        // 列表参数
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          current: 1,
          rowCount: 10,
          // 排序条件,格式：column1:asc;column2:desc
          sortStr: null,
          groupName: null,
          jobName: null,
          status: null,
          jobType: null,
          statefulFlag: null,
          startDate4Search: undefined,
          endDate4Search: undefined,
        },
        // 新建对话框是否可见，默认不可见
        dialogVisible: false,
        // 添加类别加载状态
        createLoading: false,
        // 状态下拉选项
        statusOptions: [],
        // jobType下拉选项
        jobTypeOptions: [],
        // statefulFlag下拉选项
        statefulFlagOptions: [],
        // 勾选的行
        multipleSelection: [],
        // 日期范围查询组件
        pickerOpts: {
          // 默认日期
          pickerData: undefined,
        },
      },
      // 定时任务执行记录相关参数
      jobTrace: {
        // 对话框是否可见，默认不可见
        dialogVisible: false,
        job: {
          jobId: null,
          jobName: null,
        },
      },
    };
    // 设置定时任务状态下拉选项
    this.setJobStatusOptions(opt);
    // 设置定时jobType下拉选项
    this.setJobTypeOptions(opt);
    // 设置定时statefulFlag下拉选项
    this.setJobStatefulFlagOptions(opt);
    return opt;
  },
  created() {
    this.getJobList();
  },
  watch: {
    // 切换语言重新设置状态下拉选项
    language: function () {
      this.setJobStatusOptions();
      this.setJobTypeOptions();
      this.setJobStatefulFlagOptions();
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    editJobHander(row) {
      this.job.editRow = row;
      this.job.dialogVisible = true;
    },
    // 获取定时任务列表
    getJobList() {
      this.job.listLoading = true;
      getJobList(this.job.listQuery)
        .then((response) => {
          this.job.list = response.rows.map((v) => {
            // 编辑状态，用于行内编辑
            this.$set(v, "edit", false);
            // 数据处理状态
            this.$set(v, "processing", false);
            // 保存原有值，用于取消编辑
            this.setOriginal4Job(v);
            return v;
          });
          this.job.total = response.total;
          this.job.listLoading = false;
        })
        .catch((error) => {
          this.job.listLoading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    // 查询定时任务
    handleJobFilter() {
      this.job.listQuery.current = 1;
      this.getJobList();
    },
    // 处理动态排序
    handleSort(column) {
      this.job.listQuery.sortStr = buildSortStr(column);
      this.getJobList();
    },
    // 勾选行时触发
    handleSelectionChange(val) {
      this.job.multipleSelection = val;
    },
    // 暂停定时任务
    pauseJob(row) {
      this.$confirm(
        this.$t("clocky.pauseConfirm", row),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 锁定数据
          row.processing = true;
          // 调用服务
          pauseJob(row)
            .then(() => {
              // 成功后关闭loading
              row.processing = false;
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("clocky.pauseSuccess"),
              });
              // 刷新列表
              this.getJobList();
            })
            .catch(() => {
              row.processing = false;
            });
        })
        .catch((error) => {
          console.log(`未暂停，原因 => ${error}`);
        });
    },
    // 批量暂停任务
    handleJobBatchPause() {
      if (this.job.multipleSelection.length > 0) {
        // 暂停选中的任务
        this.$confirm(
          this.$t("clocky.batchPauseConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            // 锁定数据
            this.job.listLoading = true;
            // 调用服务
            batchPauseJob(this.job.multipleSelection)
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("clocky.pauseSuccess"),
                });
                // 刷新列表
                this.getJobList();
              })
              .catch(() => {
                this.job.listLoading = false;
              });
          })
          .catch((error) => {
            console.log(`未暂停，原因 => ${error}`);
          });
      }
    },
    // 恢复定时任务
    resumeJob(row) {
      this.$confirm(
        this.$t("clocky.resumeConfirm", row),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 锁定数据
          row.processing = true;
          // 调用服务
          resumeJob(row)
            .then(() => {
              // 成功后关闭loading
              row.processing = false;
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("clocky.resumeSuccess"),
              });
              // 刷新列表
              this.getJobList();
            })
            .catch(() => {
              row.processing = false;
            });
        })
        .catch((error) => {
          console.log(`未恢复，原因 => ${error}`);
        });
    },
    // 批量恢复任务
    handleJobBatchResume() {
      if (this.job.multipleSelection.length > 0) {
        // 恢复选中的任务
        this.$confirm(
          this.$t("clocky.batchResumeConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            // 锁定数据
            this.job.listLoading = true;
            // 调用服务
            batchResumeJob(this.job.multipleSelection)
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("clocky.resumeSuccess"),
                });
                // 刷新列表
                this.getJobList();
              })
              .catch(() => {
                this.job.listLoading = false;
              });
          })
          .catch((error) => {
            console.log(`未恢复，原因 => ${error}`);
          });
      }
    },
    // 执行定时任务
    triggerJob(row) {
      this.$confirm(
        this.$t("clocky.triggerConfirm", row),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 锁定数据
          row.processing = true;
          // 调用服务
          triggerJob(row)
            .then(() => {
              // 成功后关闭loading
              row.processing = false;
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("clocky.triggerSuccess"),
              });
              // 刷新列表
              this.getJobList();
            })
            .catch(() => {
              row.processing = false;
            });
        })
        .catch((error) => {
          console.log(`未执行，原因 => ${error}`);
        });
    },
    // 批量执行任务
    handleJobBatchTrigger() {
      if (this.job.multipleSelection.length > 0) {
        // 执行选中的任务
        this.$confirm(
          this.$t("clocky.batchTriggerConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            // 锁定数据
            this.job.listLoading = true;
            // 调用服务
            batchTriggerJob(this.job.multipleSelection)
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("clocky.triggerSuccess"),
                });
                // 刷新列表
                this.getJobList();
              })
              .catch(() => {
                this.job.listLoading = false;
              });
          })
          .catch((error) => {
            console.log(`未执行，原因 => ${error}`);
          });
      }
    },
    // 删除定时任务
    handleJobDelete(row) {
      this.$confirm(
        this.$t("clocky.deleteConfirm", row),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 锁定数据
          row.processing = true;
          // 调用服务
          deleteJob(row)
            .then(() => {
              // 成功后关闭loading
              row.processing = false;
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              // 刷新列表
              this.getJobList();
            })
            .catch(() => {
              row.processing = false;
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 批量删除任务
    handleJobBatchDelete() {
      if (this.job.multipleSelection.length > 0) {
        // 删除选中的任务
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            // 锁定数据
            this.job.listLoading = true;
            // 调用服务
            batchDeleteJob(this.job.multipleSelection)
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.deleteSuccess"),
                });
                // 刷新列表
                this.getJobList();
              })
              .catch(() => {
                this.job.listLoading = false;
              });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    // 更新定时任务
    handleJobUpdate(row) {
      // 锁定数据
      row.processing = true;
      // 调用服务
      updateJob(row)
        .then(() => {
          // 成功后解锁数据
          row.processing = false;
          row.edit = false;
          // 保存原有值，用于取消编辑
          this.setOriginal4Job(row);
          // 成功提示
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          row.processing = false;
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    // 为cron赋值
    onInputCron(val, row) {
      row.cron = val;
    },
    // 添加定时任务
    addJob() {
      this.job.editRow = {};
      this.job.dialogVisible = true;
    },
    // 添加定时任务
    handleJobCreate() {
      this.job.createLoading = true;
      if (this.job.editRow.jobId) {
        new Promise(this.$refs.addJobForm.update)
          .then(() => {
            this.job.createLoading = false;
            this.job.editRow = {};
            this.closeAddJobDialog();
            // 刷新列表
            this.getJobList();
          })
          .catch(() => {
            this.job.createLoading = false;
          });
      } else {
        new Promise(this.$refs.addJobForm.submit)
          .then(() => {
            this.job.createLoading = false;
            this.closeAddJobDialog();
            // 刷新列表
            this.getJobList();
          })
          .catch(() => {
            this.job.createLoading = false;
          });
      }
    },
    // 关闭添加窗口
    closeAddJobDialog() {
      this.job.dialogVisible = false;
      // 重置表单
      this.$refs.addJobForm.reset();
    },
    // 取消编辑
    cancelJobEdit(row) {
      this.restoreOriginal4Job(row);
      row.edit = false;
    },
    // 保存原有值，用于取消编辑
    setOriginal4Job(item) {
      item.originalCron = item.cron || null;
      item.originalJobContent = item.jobContent || null;
    },
    // 还原原有值，用于取消编辑
    restoreOriginal4Job(item) {
      item.cron = item.originalCron;
      item.jobContent = item.originalJobContent;
    },
    // 设置状态下拉选项
    setJobStatusOptions(opt = this) {
      opt.job.statusOptions = [
        {
          value: 0,
          label: this.$t("clocky.jobStatus0"),
        },
        {
          value: 1,
          label: this.$t("clocky.jobStatus1"),
        },
        {
          value: 2,
          label: this.$t("clocky.jobStatus2"),
        },
        {
          value: 3,
          label: this.$t("clocky.jobStatus3"),
        },
      ];
    },
    // 设置jobType下拉选项
    setJobTypeOptions(opt = this) {
      opt.job.jobTypeOptions = [
        {
          value: "REST",
          label: this.$t("clocky.jobTypeREST"),
        },
      ];
    },
    // 设置statefulFlag下拉选项
    setJobStatefulFlagOptions(opt = this) {
      opt.job.statefulFlagOptions = [
        {
          value: "Y",
          label: this.$t("clocky.statefulFlagY"),
        },
        {
          value: "N",
          label: this.$t("clocky.statefulFlagN"),
        },
      ];
    },
    // 设置日期范围查询条件
    setDateRange(dateRange) {
      if (dateRange) {
        this.job.listQuery.startDate4Search = dateRange[0].getTime();
        this.job.listQuery.endDate4Search = dateRange[1].getTime();
      } else {
        // 清空
        this.job.listQuery.startDate4Search = null;
        this.job.listQuery.endDate4Search = null;
      }
    },
    // 查看执行记录内容
    showJobTrace(row) {
      this.jobTrace.dialogVisible = true;
      this.jobTrace.job.jobId = row.jobId;
      this.jobTrace.job.jobName = row.jobName;
    },
  },
};
</script>
<style scoped>
.job-operation-popover {
  text-align: center;
}
</style>
