var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "jobForm",
      attrs: {
        "label-position": "top",
        model: _vm.job.form,
        rules: _vm.job.formRules,
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("clocky.jobName"), prop: "jobName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.dataset.jobId ? true : false,
                      maxlength: "33",
                      "show-word-limit": true,
                      autocomplete: "off",
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.job.form.jobName,
                      callback: function ($$v) {
                        _vm.$set(_vm.job.form, "jobName", $$v)
                      },
                      expression: "job.form.jobName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 11, offset: 2 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("clocky.groupName"),
                    prop: "groupName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.dataset.jobId ? true : false,
                      maxlength: "33",
                      "show-word-limit": true,
                      autocomplete: "off",
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.job.form.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.job.form, "groupName", $$v)
                      },
                      expression: "job.form.groupName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("clocky.jobShard"),
                    prop: "shardFlag",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.job.form.shardFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.job.form, "shardFlag", $$v)
                        },
                        expression: "job.form.shardFlag",
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("clocky.jobShardYReason"),
                            placement: "top-start",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "Y" } }, [
                            _vm._v(_vm._s(_vm.$t("clocky.shardFlagY"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("clocky.jobShardNReason"),
                            placement: "top-start",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "N" } }, [
                            _vm._v(_vm._s(_vm.$t("clocky.shardFlagN"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 11, offset: 2 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("clocky.statefulFlag"),
                    prop: "statefulFlag",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.job.form.statefulFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.job.form, "statefulFlag", $$v)
                        },
                        expression: "job.form.statefulFlag",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "Y" } }, [
                        _vm._v(_vm._s(_vm.$t("clocky.statefulFlagY"))),
                      ]),
                      _c("el-radio-button", { attrs: { label: "N" } }, [
                        _vm._v(_vm._s(_vm.$t("clocky.statefulFlagN"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("clocky.jobType"), prop: "jobType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.job.form.jobType,
                        callback: function ($$v) {
                          _vm.$set(_vm.job.form, "jobType", $$v)
                        },
                        expression: "job.form.jobType",
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("clocky.jobTypeRReason"),
                            placement: "top-start",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "REST" } }, [
                            _vm._v(_vm._s(_vm.$t("clocky.jobTypeREST"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("clocky.jobTypeCReason"),
                            placement: "top-start",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "CLOUD" } }, [
                            _vm._v(_vm._s(_vm.$t("clocky.jobTypeCLOUD"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 11, offset: 2 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("clocky.cron"), prop: "cron" } },
                [
                  _c("job-cron", {
                    attrs: { value: _vm.job.form.cron },
                    on: { onInputCron: _vm.onInputCron },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.job.form.shardFlag === "N"
        ? _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "指定租户",
                    prop: "tenantIds",
                    rules: [
                      {
                        required: _vm.job.form.jobType === "CLOUD",
                        trigger: ["blur", "change"],
                        message: _vm.$t("validate.required"),
                      },
                    ],
                  },
                },
                [
                  _c("tenant-select", {
                    style: { width: "100%" },
                    attrs: { model: 2, multiple: true },
                    model: {
                      value: _vm.job.form.tenantIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.job.form, "tenantIds", $$v)
                      },
                      expression: "job.form.tenantIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("clocky.jobContent"),
                    prop: "jobContent",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "160",
                      "show-word-limit": true,
                      autosize: { minRows: 4, maxRows: 6 },
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.job.form.jobContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.job.form, "jobContent", $$v)
                      },
                      expression: "job.form.jobContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }