var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "searchJobTraceForm",
          attrs: { inline: true, model: _vm.jobTrace.listQuery },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "finalStatus" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$t("commons.status"),
                  },
                  model: {
                    value: _vm.jobTrace.listQuery.finalStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.jobTrace.listQuery, "finalStatus", $$v)
                    },
                    expression: "jobTrace.listQuery.finalStatus",
                  },
                },
                _vm._l(_vm.jobTrace.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticClass: "filter-item",
                attrs: {
                  type: "datetimerange",
                  "picker-options": _vm.jobTrace.pickerOpts,
                  "range-separator": _vm.$t("commons.rangeSeparator"),
                  "start-placeholder": _vm.$t("commons.startDate"),
                  "end-placeholder": _vm.$t("commons.endDate"),
                },
                on: { change: _vm.setDateRange },
                model: {
                  value: _vm.jobTrace.pickerOpts.pickerData,
                  callback: function ($$v) {
                    _vm.$set(_vm.jobTrace.pickerOpts, "pickerData", $$v)
                  },
                  expression: "jobTrace.pickerOpts.pickerData",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    loading: _vm.jobTrace.listLoading,
                  },
                  on: { click: _vm.handleJobTraceFilter },
                },
                [_vm._v(_vm._s(_vm.$t("commons.search")) + "\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.jobTrace.listLoading,
              expression: "jobTrace.listLoading",
            },
          ],
          attrs: {
            data: _vm.jobTrace.list,
            "element-loading-text": "Loading",
            size: "small",
            border: "",
            fit: "",
            "highlight-current-row": "",
            stripe: "",
          },
          on: { "sort-change": _vm.handleJobTraceSort },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              resizable: false,
              label: _vm.$t("commons.index"),
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "creationDate",
              sortable: "custom",
              label: _vm.$t("commons.creationDate"),
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              scope.row.creationDate,
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalTime",
              sortable: "custom",
              label: _vm.$t("clocky.totalTime"),
              width: "120",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("commons.status"),
              prop: "finalStatus",
              sortable: "custom",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("finalStatusTagFilter")(
                            scope.row.finalStatus
                          ),
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("finalStatusFilter")(scope.row.finalStatus)
                          ) + "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "returnValue", label: _vm.$t("clocky.returnValue") },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.jobTrace.total > 0,
            expression: "jobTrace.total>0",
          },
        ],
        attrs: {
          total: _vm.jobTrace.total,
          page: _vm.jobTrace.listQuery.current,
          limit: _vm.jobTrace.listQuery.rowCount,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.jobTrace.listQuery, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.jobTrace.listQuery, "rowCount", $event)
          },
          pagination: _vm.getJobTraceList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }